<template>
  <div class="container">
    <h1>Wellfest pagelist</h1>
    <ul>
      <li>
        <span class="heading">
          <span class="id">ID</span>
          <span class="page">Page Name</span>
          <span class="phase">Status</span>
          <span class="phase">Phase</span>
        </span>
      </li>
      <li v-for="page in pages" :key="page.id" :class="page.status">
        <router-link :to="page.to">
          <div class="id">{{ page.id }}</div>
          <div class="page">
            <span class="name">{{ page.name }}</span>
            <span class="pathTo">{{ page.to }}</span>
          </div>
          <div class="phase">{{ page.status }}</div>
          <div class="phase">{{ page.phase }}</div>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
const pages = [
  {
    id: "lg-101",
    name: "ログイン",
    to: `/login`,
    phase: "1",
    status: "API連携済み",
  },
  {
    id: "lg-001",
    name: "本人確認_パスワード設定",
    to: `/auth/init/input?token=token-test`,
    phase: "1",
    status: "API連携済み",
  },
  {
    id: "lg-201",
    name: "パスワードリマインダー",
    to: `/reminder/input`,
    phase: "1",
    status: "API連携済み",
  },
  {
    id: "lg-202",
    name: "パスワードリマインダー送信完了",
    to: `/reminder/sendcomp`,
    phase: "1",
    status: "API連携済み",
  },
  {
    id: "lg-203",
    name: "パスワード再設定",
    to: `/reminder/1234567/input?token=fasdfasdfe34`,
    phase: "1",
    status: "API連携済み",
  },
  {
    id: "in-001",
    name: "お知らせ一覧",
    to: `/informations?isOnlyImportant=true&page=1&pageSize=10`,
    phase: "1",
    status: "API連携済み",
  },
  {
    id: "ma-001-1/ma-001-2",
    name: "マニフェスト一覧 全て （排出/運搬）",
    to: `/manifests/all`,
    phase: "1",
    status: "API連携済み",
  },
  {
    id: "ma-002-1",
    name: "マニフェスト一覧 交付前",
    to: `/manifests/reserve`,
    phase: "1",
    status: "API連携済み",
  },
  {
    id: "ma-003-1",
    name: "マニフェスト一覧 進行中",
    to: `/manifests/progress`,
    phase: "1",
    status: "API連携済み",
  },
  {
    id: "ma-004-1",
    name: "マニフェスト一覧 完了",
    to: `/manifests/complete`,
    phase: "1",
    status: "API連携済み",
  },
  {
    id: "ma-002-2",
    name: "マニフェスト一覧 終了報告前",
    to: `/manifests/collectreport/before`,
    phase: "1",
    status: "API連携済み",
  },
  {
    id: "ma-003-2",
    name: "マニフェスト一覧 終了報告済",
    to: `/manifests/collectreport/after`,
    phase: "1",
    status: "API連携済み",
  },
  {
    id: "ma-101-1",
    name: "マニフェスト詳細",
    to: `/manifest/123`,
    phase: "1",
    status: "API連携済み",
  },
  {
    id: "ma-201-1",
    name: "マニフェスト予約",
    to: `/manifest/input`,
    phase: "1",
    status: "API連携済み",
  },
  {
    id: "ma-202-1",
    name: "マニフェスト一覧 空登録",
    to: `/manifest/temporaryinput`,
    phase: "1",
    status: "API連携済み",
  },
  {
    id: "ma-201-2",
    name: "運搬終了報告登録",
    to: `/manifest/collect/report/123456789`,
    phase: "1",
    status: "",
  },
  {
    id: "ma-301-2",
    name: "運搬終了報告修正",
    to: `/manifest/collect/report/edit/123456789`,
    phase: "1",
    status: "",
  },
  {
    id: "ma-401-1",
    name: "マニフェスト編集",
    to: `/manifest/edit/123`,
    phase: "1",
    status: "API連携済み",
  },
  {
    id: "ma-501-3",
    name: "マニフェスト代理登録",
    to: `/manifest/proxy/input`,
    phase: "1",
    status: "API連携済み",
  },
  {
    id: "",
    name: "CSVアップロード",
    to: `/manifest/csvupload/`,
    phase: "",
    status: "",
  },
  {
    id: "sc-104-1、sc-104-2",
    name: "スケジュール一覧【排出・運搬】",
    to: `/schedule`,
    phase: "1",
    status: "API連携済み",
  },
  {
    id: "hm-101",
    name: "ホーム【CBA】",
    to: `/home`,
    phase: "1",
    status: "API連携済み",
  },
  {
    id: "co-001",
    name: "CBA契約業者一覧",
    to: `/generator/contract/`,
    phase: "1",
    status: "API連携済み",
  },
  {
    id: "co-101",
    name: "CBA契約業者詳細",
    to: `/generator/contract/123`,
    phase: "1",
    status: "API連携済み",
  },
  {
    id: "co-102_1",
    name: "CBA契約登録1 排出業者",
    to: `/generator/contract/step1/input`,
    phase: "1",
    status: "API連携済み",
  },
  {
    id: "co-102_2",
    name: "CBA契約登録2 排出業者",
    to: `/generator/contract/step2/input`,
    phase: "1",
    status: "API連携済み",
  },
  {
    id: "co-105_1",
    name: "CBA契約登録編集1 排出業者",
    to: `/generator/contract/step1/edit/123`,
    phase: "1",
    status: "API連携済み",
  },
  {
    id: "co-105_2",
    name: "CBA契約登録編集2 排出業者",
    to: `/generator/contract/step2/edit/123`,
    phase: "1",
    status: "API連携済み",
  },
  {
    id: "tr-001",
    name: "処理事業者一覧",
    to: `/operate`,
    phase: "1",
    status: "API連携済み",
  },
  {
    id: "tr-002",
    name: "運搬事業者詳細",
    to: `/collector/123`,
    phase: "1",
    status: "API連携済み",
  },
  {
    id: "tr-003-1",
    name: "運搬事業者登録1",
    to: `/collector/step1/input`,
    phase: "1",
    status: "API連携済み",
  },
  {
    id: "tr-003-2",
    name: "運搬事業者登録2",
    to: `/collector/step2/input`,
    phase: "1",
    status: "API連携済み",
  },
  {
    id: "tr-006-1",
    name: "運搬事業者編集1",
    to: `/collector/step1/edit/123`,
    phase: "1",
    status: "API連携済み",
  },
  {
    id: "tr-006-2",
    name: "運搬事業者編集2",
    to: `/collector/step2/edit/123`,
    phase: "1",
    status: "API連携済み",
  },
  {
    id: "co-202",
    name: "CBA契約登録 運搬業者",
    to: `/collector/cbacontract/input/123`,
    phase: "1",
    status: "API連携済み",
  },
  {
    id: "co-205",
    name: "CBA契約編集 運搬業者",
    to: `/collector/cbacontract/edit/123`,
    phase: "1",
    status: "API連携済み",
  },
  {
    id: "di-002",
    name: "処分事業者詳細",
    to: `/disposer/123`,
    phase: "1",
    status: "API連携済み",
  },
  {
    id: "di-003-1",
    name: "処分事業者登録1",
    to: `/disposer/step1/input`,
    phase: "1",
    status: "API連携済み",
  },
  {
    id: "di-003-2",
    name: "処分事業者登録2",
    to: `/disposer/step2/input`,
    phase: "1",
    status: "API連携済み",
  },
  {
    id: "di-006-1",
    name: "処分事業者編集1",
    to: `/disposer/step1/edit/123`,
    phase: "1",
    status: "API連携済み",
  },
  {
    id: "di-006-2",
    name: "処分事業者編集2",
    to: `/disposer/step2/edit/123`,
    phase: "1",
    status: "API連携済み",
  },
  {
    id: "co-308",
    name: "プロフィール詳細",
    to: `/profile`,
    phase: "1",
    status: "API連携済み",
  },
  {
    id: "co-309",
    name: "プロフィール編集",
    to: `/profile/edit`,
    phase: "1",
    status: "API連携済み",
  },
  {
    id: "co-301-2",
    name: "アカウント一覧",
    to: `/account`,
    phase: "1",
    status: "API連携済み",
  },
  {
    id: "en-001-1",
    name: "委託契約書一覧",
    to: `/entrust`,
    phase: "1",
    status: "API連携済み",
  },
  {
    id: "en-002",
    name: "委託契約書詳細",
    to: `/entrust/123`,
    phase: "1",
    status: "API連携済み",
  },
  {
    id: "en-003",
    name: "委託契約書登録",
    to: `/entrust/input`,
    phase: "1",
    status: "API連携済み",
  },
  {
    id: "en-006",
    name: "委託契約書編集",
    to: `/entrust/edit/1234545`,
    phase: "1",
    status: "API連携済み",
  },
  {
    id: "ro-001-1",
    name: "ルート一覧_CBAと上位階層排出担当者利用",
    to: `/route`,
    phase: "1",
    status: "API連携済み",
  },
  {
    id: "ro-002",
    name: "ルート詳細",
    to: `/route/123`,
    phase: "1",
    status: "API連携済み",
  },
  {
    id: "ro-003",
    name: "ルート編集",
    to: `/route/edit/123`,
    phase: "1",
    status: "API連携済み",
  },
  {
    id: "ro-004",
    name: "ルート複製",
    to: `/route/duplicate?routeId=123`,
    phase: "1",
    status: "API連携済み",
  },
  {
    id: "it-001",
    name: "品目一覧",
    to: `/cbaitem`,
    phase: "1",
    status: "API連携済み",
  },
  {
    id: "it-003",
    name: "品目登録",
    to: `/cbaitem/input`,
    phase: "1",
    status: "API連携済み",
  },
  {
    id: "it-006",
    name: "品目編集",
    to: `/cbaitem/edit/1`,
    phase: "1",
    status: "API連携済み",
  },
  {
    id: "it-009",
    name: "廃棄物名称登録",
    to: `/wastename/input?cbaitemId=1`,
    phase: "1",
    status: "API連携済み",
  },
  {
    id: "it-010",
    name: "廃棄物名称編集",
    to: `/wastename/edit/11`,
    phase: "1",
    status: "API連携済み",
  },
  {
    id: "pe-001-1, pe-001-2",
    name: "許可証一覧",
    to: `/licence`,
    phase: "1",
    status: "API連携済み",
  },
  {
    id: "pe-002-1",
    name: "運搬許可書詳細",
    to: `/licence/collect/123`,
    phase: "1",
    status: "API連携済み",
  },
  {
    id: "pe-002-2",
    name: "処分許可書詳細",
    to: `/licence/disposal/123`,
    phase: "1",
    status: "API連携済み",
  },
  {
    id: "ag-001",
    name: "利用規約",
    to: `/static/terms`,
    phase: "1",
    status: "API無し",
  },
  {
    id: "ag-101",
    name: "プライバシーポリシー",
    to: `/static/privacypolicy`,
    phase: "1",
    status: "API無し",
  },
  {
    id: "",
    name: "エラー画面",
    to: `/error`,
    phase: "1",
    status: "API無し",
  },
];

export default {
  data() {
    return {
      pages,
    };
  },
};
</script>

<style lang="scss" scoped>
.container {
  padding: 20px 60px;
}
h1 {
  color: #3785ad;
  font-size: 3.2rem;
  text-align: center;
  margin-bottom: 40px;
}
ul {
  padding-left: 0;

  li {
    font-size: 1.6rem;
    list-style: none;
    & ~ li {
      border-bottom: 1px solid #ddd;
    }
    a,
    .heading {
      display: flex;
      justify-content: space-between;
      text-decoration: none;
      color: inherit;
      padding: 10px;
    }

    a {
      transition: opacity 0.3s;
      &:hover {
        opacity: 0.5;
      }
      &[href="./.html"] {
        opacity: 0.3;
        pointer-events: none;
      }
    }
    .heading {
      background-color: #eaf2f7;
    }
    .id {
      width: 20%;
    }
    .page {
      width: 50%;
      .name {
        font-weight: 700;
        display: block;
      }
    }
    .phase {
      width: 10%;
      text-align: center;
    }

    .date {
      width: 20%;
    }
    .title {
      padding-right: 20px;
    }
    .date {
      display: block;
      width: 120px;
      text-align: right;
      position: relative;
    }
  }
}
</style>
